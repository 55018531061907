<template>
  <div>
    <el-dialog
      title="删除水单号范围"
      :visible.sync="dialogTableVisible"
      width="30%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="onCloseDialog('formRules')"
    >
      <el-table :data="tableData" border height="400">
        <el-table-column prop="merge" label="水单号范围"> </el-table-column>
        <el-table-column label="操作" width="250">
          <template slot-scope="scope" v-if="scope.row.ListGuid !== ''">
            <el-button
              type="warning"
              size="mini"
              @click="onDeleteOutUseWaterSingleScope(scope.row)"
            >
              删除未使用水单号
            </el-button>
            <el-button
              type="danger"
              size="mini"
              @click="onWaterSingleScope(scope.row)"
            >
              删除范围
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import setting from "@/api/setting.js";
export default {
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      dialogTableVisible: false,
    };
  },
  methods: {
    onCloseDialog(formName) {
      this.dialogTableVisible = false;
      this.$emit("closeDialog");
    },

    openDeleteWaterAingleScope() {
      this.dialogTableVisible = true;
    },

    closeDeleteWaterAingleScope() {
      this.dialogTableVisible = false;
    },

    onDeleteOutUseWaterSingleScope(event) {
      if (event.ListGuid == "") {
        return this.$message({
          message: "不能删除全部",
          type: "warning",
        });
      }

      this.$confirm(
        `是否删除该范围‘${event.merge}’里面的未使用水单号`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await setting.deleteBillNoRange({
              list_guid: event.ListGuid,
            });
            if (errcode == 0) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch((err) => {});
    },

    onWaterSingleScope(event) {
      if (event.ListGuid == "") {
        return this.$message({
          message: "不能删除全部",
          type: "warning",
        });
      }

      this.$confirm(`是否删除该‘${event.merge}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await setting.deleteBillNoList({
              list_guid: event.ListGuid,
            });
            if (errcode == 0) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
              this.$emit("deleteWaterSingleScopeSuccess");
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch((err) => {});
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .el-dialog {
}
</style>