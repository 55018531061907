<template>
  <div class="water-single">
    <div class="df-row">
      <div class="df-col">
        <el-date-picker
          v-model="startAndEndDate"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          size="small"
        >
        </el-date-picker>
      </div>
      <div class="df-col">
        <el-select
          v-model="selectConditionType"
          placeholder="水单号范围"
          size="small"
          @change="onChangeWaterSingle"
        >
          <el-option
            v-for="item in conditionsData"
            :key="item.ListGuid"
            :label="item.merge"
            :value="item.merge"
          >
          </el-option>
        </el-select>
      </div>
      <div class="df-col">
        <el-input
          v-model="searchWaterSingle"
          size="small"
          placeholder="请输入水单号"
        ></el-input>
      </div>
      <div class="df-col">
        <el-button type="primary" size="small" @click="onSearchWaterSingle"
          >搜索</el-button
        >
        <el-button type="primary" size="small" @click="onAddWaterSingle"
          >新增水单号</el-button
        >
        <el-button type="primary" size="small" @click="onReleaseWaterSingle"
          >释放水单号</el-button
        >
        <el-button type="primary" size="small" @click="onDeleteWaterAingleScope"
          >删除水单号范围</el-button
        >
      </div>
    </div>
    <!--  -->
    <el-table
      :data="tableData"
      border
      height="calc(100vh - 220px)"
      v-loading="loading"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="40"> </el-table-column>
      <el-table-column prop="BillNo" label="水单号" width="180">
      </el-table-column>
      <el-table-column label="添加日期" width="180">
        <template slot-scope="scope">{{
          dateFormat(scope.row.AddTime)
        }}</template>
      </el-table-column>
      <el-table-column prop="address" label="是否占用">
        <template slot-scope="scope">{{
          scope.row.BillNoState | whether
        }}</template>
      </el-table-column>
      <el-table-column prop="address" label="使用时间">
        <template slot-scope="scope">{{
          dateFormat(scope.row.UseTime)
        }}</template>
      </el-table-column>
      <el-table-column prop="address" label="使用类型">
        <template slot-scope="scope">
          {{
            scope.row.UseBillType == 0
              ? "消费"
              : scope.row.UseBillType == 1
              ? "开卡"
              : "充值"
          }}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="充卡独占">
        <template slot-scope="scope">{{
          scope.row.VipOccupy | whether
        }}</template>
      </el-table-column>
      <el-table-column prop="address" label="操作">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="mini"
            @click="onDeleteWaterSingle(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!--  -->
    <AddWaterAingle
      ref="AddWaterAingle"
      @closeDialog="onCloseWaterAingleDialog"
    >
    </AddWaterAingle>
    <DeleteWaterAingleScope
      :tableData="conditionsAingleData"
      ref="DeleteWaterAingleScope"
      @closeDialog="onCloseDeleteWaterAingleScopeDialog"
      @deleteWaterSingleScopeSuccess="onDeleteWaterSingleScopeSuccess"
    >
    </DeleteWaterAingleScope>
  </div>
</template>

<script>
import api from "@/api/setting.js";
// import item from "@/cashier/order/item.js";
import AddWaterAingle from "./components/add-bill-no.vue";
import DeleteWaterAingleScope from "./components/delete-bill-no-scope.vue";
export default {
  components: { AddWaterAingle, DeleteWaterAingleScope },

  data() {
    return {
      startAndEndDate: [],
      searchWaterSingle: "",
      selectConditionType: "",
      conditionsData: [],
      conditionsAingleData: [],
      tableData: [],
      startBillNo: "",
      endBillNo: "",
      changeSelectWaterSingle: [],
      loading: false,
    };
  },

  mounted() {
    this.startAndEndDate = [
      this.util.getNowMonthToday(),
      this.util.getNowMonthToday(),
    ];
    this.queryConditionData();
    this.initPageData();
  },

  filters: {
    whether(param) {
      return param == 0 ? "否" : "是";
    },
  },

  methods: {
    dateFormat(time) {
      let date = this.util.dateFormat("YYYY-mm-dd", new Date(time * 1000));
      return date;
    },

    async initPageData() {
      this.loading = true;
      try {
        let submitData = {
          start_date: this.startAndEndDate[0],
          end_date: this.startAndEndDate[1],
          bill_no: this.searchWaterSingle,
          start_bill_no: this.startBillNo,
          end_bill_no: this.endBillNo,
        };

        let { data } = await api.getBillNoList(submitData);
        this.tableData = data;
      } catch (e) {}
      this.loading = false;
    },

    async queryConditionData() {
      try {
        let { data } = await api.getBillNoBatchList();
        let tArray = [
          {
            EndBillNo: "",
            ListGuid: "",
            StartBillNo: "",
            merge: "全部范围",
          },
        ];
        data.forEach((item) => {
          let text = item.StartBillNo + "-到-" + item.EndBillNo;
          tArray.push({ merge: text, ...item });
        });
        this.conditionsData = tArray;
        this.conditionsAingleData = tArray.filter((item) => item.ListGuid);
      } catch (e) {
        console.log(e);
      }
    },

    onChangeWaterSingle(event) {
      this.conditionsData.forEach((item) => {
        if (item.merge == event) {
          this.startBillNo = item.StartBillNo;
          this.endBillNo = item.EndBillNo;
        }
      });
    },

    onSearchWaterSingle() {
      this.initPageData();
    },

    onAddWaterSingle() {
      this.$refs.AddWaterAingle.openAddWaterAingle();
    },

    handleSelectionChange(event) {
      this.changeSelectWaterSingle = event;
      console.log(event);
    },

    onReleaseWaterSingle() {
      if (this.changeSelectWaterSingle.length == 1) {
        let [water] = this.changeSelectWaterSingle;
        this.$confirm(`是否释放该‘${water.BillNo}’`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            try {
              let { data, errcode, errmsg } = await api.releaseBillNo({
                bill_no: water.BillNo,
              });
              if (errcode == 0) {
                this.$message({
                  message: "释放成功!",
                  type: "success",
                });
                this.initPageData();
              } else {
                this.$message.error(errmsg);
              }
            } catch (e) {
              console.error(e);
            }
          })
          .catch((err) => {});
      } else {
        this.$message({
          message:
            this.changeSelectWaterSingle.length > 1
              ? "只能勾选一个水单号释放！"
              : "请选择勾选水单号！",
          type: "warning",
          duration: 1000,
        });
      }
    },

    onDeleteWaterAingleScope() {
      this.$refs.DeleteWaterAingleScope.openDeleteWaterAingleScope();
    },

    async onDeleteWaterSingle(event) {
      this.$confirm(`是否删除该‘${event.BillNo}’`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            let { data, errcode, errmsg } = await api.deleteBillNo({
              bill_no: event.BillNo,
            });
            if (errcode == 0) {
              this.$message({
                message: "删除成功!",
                type: "success",
              });
              this.initPageData();
            } else {
              this.$message.error(errmsg);
            }
          } catch (e) {
            console.error(e);
          }
        })
        .catch((err) => {});
    },

    onCloseWaterAingleDialog() {
      this.queryConditionData();
      this.initPageData();
    },

    onCloseDeleteWaterAingleScopeDialog() {
      this.queryConditionData();
      this.initPageData();
    },

    onDeleteWaterSingleScopeSuccess() {
      this.queryConditionData();
    },
  },
};
</script>

<style lang="less" scoped>
.water-single {
  height: 100%;
  width: 100%;
  padding-top: 20px;
}

.df-row {

  .df-col{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  .el-date-editor {
    width: 100% !important;
  }

  .el-button{
    width: 100%;
  }
}

.range {
  > span:nth-child(2) {
    padding: 0 10px;
  }
}
</style>